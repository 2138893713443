import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Icon, Label, Segment, Tab, Button, Modal } from "semantic-ui-react";
import utility from "../../commons/utility";
import UserDetails from "../../userInfo/UserDetails";
import ajax from '../../commons/agent.js'
import DisplayMessage from '../../commons/displayMessage.jsx';

class AgentDetailCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			messageType: '', messageHeader: '', message: [],
		}
	}

	editUserDetails = (userDetails) => {
		this.props.history.push({
			pathname: `/dashboard/editAgent/${this.props.organization.organizationBpid}`,
			state: {
				...userDetails,
			},
		});
	}

	enableDisableUser = () => {
		let self = this
		let Vo = {
			action: this.state.confirmEnableDisableUser.state === 'Enabled' ? 'disable' : 'enable',
			userId: this.state.confirmEnableDisableUser.userId,
		}
		self.setState({ isEnablingdisablingUser: true })
		ajax.OrganizationMaster.enabledisableUser(Vo).end((err, response) => {
			self.setState({ isEnablingdisablingUser: false, confirmEnableDisableUser: false })
			if (!err && response) {
				this.props.partnerDetailPgContext.onLoad()
			}
			else {
				utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
			}
		});
	}


	render() {
		const { OrgDetailsData, t, PartnerDetailsContext, organization } = this.props
		const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())
		return (
			<>
				<DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

				{OrgDetailsData && OrgDetailsData.organizationUsersVo &&
					<Grid as={Segment} loading={PartnerDetailsContext.state.isFatchingAgentsDetails} className='py-5px'>
						{OrgDetailsData.organizationUsersVo.map(item =>
							<Grid.Column stretched key={item.userId} className='py-5px' computer={4} tablet={8} mobile={16}>
								<Segment className=''>
									<Grid columns='equal'>
										<Grid.Column width={2} textAlign='center' className='pr-0'>
											<Icon className='colPrimary' name='user' size='large' />
										</Grid.Column>
										<Grid.Column verticalAlign='middle' className='px-5px'>
											<div className='colBlack textBold textNormal' title={`${item.firstName} ${item.lastName}`}>{utility.elipses(`${item.firstName} ${item.lastName}`, 24)}</div>
											{/* <p className='colGrey40 my-0 mb-0 textSmaller'>{utility.timeAgo(item.createdAt)}</p> */}
										</Grid.Column>
										<Grid.Column className='mx-0'>
											{item.isAdmin ?
												<Label size='small' color='yellow' ribbon='right'>Super Agent</Label>
												:
												<Label size='small' color='orange' ribbon='right'>Agent</Label>
											}
										</Grid.Column>
									</Grid>
									{(
										(organization.organizationBpid === UserDetails.bpId && UserDetails.manager === true) ||
										UserDetails.platformadmin || orgCategory === 'Improvement Service' ||
										(organization.organizationBpid === UserDetails.bpId && UserDetails.employee)
									) &&
										<>
											<Grid columns='equal' className='my-0 pt-1'>
												<Grid.Column className='py-0'>
													<div className='textNormal colGrey40'>
														<span>{t('userId')} : </span>
														<span >{item.userId}</span>
													</div>
													<div className='textNormal colGrey40'>
														<span>{t('emailId')} : </span>
														<span title={item.email}>
															<a href={`mailto:${item.email}`}>
																{utility.elipses(item.email, 24)}
															</a>
														</span>
													</div>
													<div className='textNormal colGrey40'>
														<span>{t('contactNumber')} : </span>
														<span >{item.contactNumber}</span>
													</div>
												</Grid.Column>
											</Grid>
											<Grid columns='equal' className='mt-0'>
												<Grid.Column className='pt-0' verticalAlign='middle' textAlign='left'>
													<span>
														{item.state === 'Enabled' && <><Label empty size='tiny' color='green' circular></Label> Active</>}
														{item.state === 'Disabled' && <><Label empty size='tiny' color='red' circular></Label> Inactive</>}
													</span>
													{/* {item.state === 'Pending' && <Label empty size='tiny' color='orange' circular></Label>} */}
													{/* <span > {item.state}</span> */}
												</Grid.Column>
											</Grid>
											<Grid columns='equal' className='mt-0'>
												<Grid.Column className='pt-0' verticalAlign='middle' textAlign='right'>
													{/* do it based on user role manager								 */}
													{(UserDetails.platformadmin || orgCategory === 'Improvement Service' || UserDetails.manager === true || item.userId == utility.fetchUserId()) &&
														<Button size='small' className='customBtn1Secondary py-5px' onClick={() => this.editUserDetails(item)}> <Icon name='edit' /> Edit  </Button>
													}
													{(UserDetails.platformadmin || orgCategory === 'Improvement Service' || (UserDetails.manager === true && !item.isAdmin)) &&
														<Button size='small' className='customBtn1Secondary py-5px mr-0' onClick={() => this.setState({ confirmEnableDisableUser: item })}> <Icon name={item.state === 'Enabled' ? 'ban' : 'check'} /> {item.state === 'Enabled' ? 'Deactivate' : 'Activate'} </Button>
													}
												</Grid.Column>
											</Grid>
										</>
									}
								</Segment>
							</Grid.Column>
						)}
					</Grid>
				}

				{/* confirmEnableDisableUser Modal */}
				{this.state.confirmEnableDisableUser && <Modal open={this.state.confirmEnableDisableUser} dimmer='inverted'>
					<Modal.Content>
						<div className='textBig colPrimary textBold mb-1'></div>
						<div className='textBig textBold '>{`Are you sure you want to ${this.state.confirmEnableDisableUser.state === 'Enabled' ? 'deactivate' : 'activate'} ${this.state.confirmEnableDisableUser.firstName} ${this.state.confirmEnableDisableUser.lastName} ?`}</div>
					</Modal.Content>
					<Modal.Actions>
						<Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ confirmEnableDisableUser: false })} loading={this.state.isEnablingdisablingUser}>Cancel</Button>
						<Button className='customBtn1Primary' onClick={() => this.enableDisableUser()} loading={this.state.isEnablingdisablingUser}>Yes</Button>
					</Modal.Actions>
				</Modal>}
			</>
		)
	}
}

export default withTranslation()(withRouter(AgentDetailCard))

