import React, { Component } from "react";
import { Grid, Icon, List, Image, Rating, Segment, Sidebar, Button, Label } from "semantic-ui-react";
import "../css/dashboard.css";
import "../css/partners.css";
import PartnerDetailsTabs from "./partnerDetailsTabs.jsx";
import { Link, withRouter } from "react-router-dom";
import ajax from '../../commons/agent.js'
import PageHeader from "../PageHeader.jsx";
import { withTranslation } from "react-i18next";
import AgentDetailCard from "./AgentDetailCard.jsx";
import utility from "../../commons/utility";
import UserDetails from "../../userInfo/UserDetails";

class PartnerDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      organization: null,
      isFatchingAgentsDetails: false,
      OrgDetailsData: null,
    }
  }

  componentDidMount() {
    this.onLoad()
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.organizationId !== this.props.organizationId) {
  //     this.organizationDetailFetch(this.props.organizationId)
  //   }
  // }

  onLoad = () => {
    this.setState({ organization: this.props.fetchOrganizationList().filter(org => org.organizationBpid == this.props.match.params.bpid)[0], isFatchingAgentsDetails: true })

    this.setState({ isFatchingAgentsDetails: true })
    ajax.OrganizationMaster.fetchOrganizationByBpId(this.props.match.params.bpid).end((err, res) => {
      this.setState({ isFatchingAgentsDetails: false })
      if (!err && res) {
        this.setState({ OrgDetailsData: res.body })
      }
      else {
        utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
      }
    })
  }

  editOrgDetails = (orgDetails) => {
    this.props.history.push({
      pathname: `/dashboard/editOrganisation`,
      state: {
        ...orgDetails,
      },
    });
  }


  render() {
    const { t } = this.props
    const { organization } = this.state
    const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())

    return (
      <>
        <PageHeader customPath={`/dashboard/organisations/${organization ? organization.organizationName : `${t('partnerDetails')}`}`} />
        {organization && <>
          <Grid>
            <Grid.Column width={16} className="py-0">
              <Image src="/images/Banner-Image.png" style={{ maxHeight: '100px', objectFit: 'cover' }} fluid />
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column computer={10} tablet={16} mobile={16} className="py-0">
              <Grid className="mt-1">
                <Grid.Column width={4} textAlign='right'>
                  <Icon className="mt-n8 colWhite bgPrimary" bordered size='huge' name='building' />
                </Grid.Column>
                <Grid.Column width={11} className='pl-0 py-0'>
                  <span className='textBig textBold'> {organization.organizationName} </span>
                  {((organization.organizationBpid === UserDetails.bpId && UserDetails.manager === true) ||
                    UserDetails.platformadmin || orgCategory === 'Improvement Service'
                  ) &&
                    <Button style={{ position: 'relative', top: '-4px' }} size='small' className='customBtn1Secondary py-5px' onClick={() => this.editOrgDetails(organization)}> <Icon name='edit' /> Edit  </Button>
                  }

                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column computer={16} tablet={16} mobile={16} className='py-0 mx-1'>
              <Segment >
                <Grid columns='equal'>
                  <Grid.Column>
                    <div className='contentLabel'>Website</div>
                    <div className='contentMedium'>
                      <a href={`${organization.website}`} target="_blank">
                        {organization.website}
                      </a>
                    </div>

                    <div className='contentLabel'>Contact</div>
                    <div className='contentMedium'>{organization.contactNumber}</div>
                    <div className='contentLabel'>Email</div>
                    <div className='contentMedium'>
                      <a href={`mailto:${organization.emailId}`}>
                        {organization.emailId}
                      </a>
                    </div>
                  </Grid.Column>

                  <Grid.Column>
                    <div className='contentLabel'>Address</div>
                    <div className='contentMedium'>
                      {organization.addressLine1}<br />
                      {organization.addressLine2}<br />
                      {organization.city}<br />
                      {organization.zipCode} <br />
                      {/* {organization.country}<br /> */}
                    </div>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>

          <Grid columns='equal'>
            <Grid.Column verticalAlign='middle'>
              <div className=' colPrimary textMedium textBold px-1'>User(s) Details</div>
            </Grid.Column>
            {(
              (organization.organizationBpid === UserDetails.bpId && UserDetails.manager === true) ||
              UserDetails.platformadmin || orgCategory === 'Improvement Service'
            ) &&
              < Grid.Column textAlign='right'>
                <Button size='small' as={Link} to={`/dashboard/registerAgent/${organization.organizationBpid}`} className='customBtn1Primary mr-1' >{(UserDetails.platformadmin || orgCategory === 'Improvement Service') ? 'Add Super Agent' : 'Add Agent'}</Button>
              </Grid.Column>
            }
          </Grid>

          <Grid className="m-0">
            <Grid.Column className='pt-0'>
              <AgentDetailCard partnerDetailPgContext={this} fetchOrganizationCategory={this.props.fetchOrganizationCategory} OrgDetailsData={this.state.OrgDetailsData} organization={organization} PartnerDetailsContext={this} />
            </Grid.Column>
          </Grid>

        </>}
      </>
    );
  }
}

export default withTranslation()(withRouter(PartnerDetails))
