import ajax from '../../commons/agent.js';
import utility from '../../commons/utility.js';

function notificationAction(notification, context, fetchLatestNotifications) {
    ajax.Notifications.displayNotification(notification.notificationId).end((err, response) => {
        utility.handleErrors(err);
        context.fetchNotificationCount()
        fetchLatestNotifications()
    });
    if (notification.isActionable === true) {
        if (notification.objectCode != null) {
            switch (notification.actionVerb) {

                // case 'Placed With Modified Terms!':
                // case 'Placed Modified Price and Additional Term(s)!':
                // case 'Placed With Modified Price and Term(s)!':
                // case 'Placed With Modified Price!':
                // case 'Under Price and Term negotiation !!!':
                // case 'Placed With Modified Price and Rejection of Negotiable Term(s)!':
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+'changePrice'+'/'+'actOnTerms' );
                //     event.preventDefault();
                //     break;
                // case 'is about to Expire':
                //     context.props.history.push( '/abouttoexpire/'+notification.objectCode);
                //     event.preventDefault();
                //     break;
                // case 'Rejected-Terms Unacceptable':
                // case 'Under Term Negotiation':
                // case 'Created and is under Price and Term Negotiaton':
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // case 'Created and is Under No. of Units and Term Negotiation':
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // case 'Created and is under Price, No. of Units & Term Negotiaton':
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // case 'Created,Price & No. of Units are Accepted, under Term Negotiaton':
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // case 'Created and is under Term Negotiaton' :
                // case 'Created and is Under Price Negotiaton' :
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // break;
                // case 'Created and is Under No. of Units Negotiation' :
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // break;
                // case 'Created and is Under Price & No. of Units Negotiation' :
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // break;
                // case 'is Under Price Negotiation' :
                //     context.props.history.push( '/modifiedprice/'+notification.objectCode+'/'+' '+'/'+' ' );
                //     event.preventDefault();
                // break;
                                  
                case 'has been Transferred. Awaiting your council to action it':
                    context.props.history.push(`/dashboard/transferRequests`);
                    break;
                case 'logged':
                    context.props.history.push(`/dashboard/helpdeskQueryDashboard`);
                    break;
                case 'assigned to your organization':
                    context.props.history.push(`/dashboard/helpdeskQueryDashboard`);
                    break;
                case 'assigned to you':
                    context.props.history.push(`/dashboard/helpdeskQueryDashboard`);
                    break;
                case 'received':
                    context.props.history.push(`/dashboard/helpdeskQueryDashboard`);
                    break;
               
            }
        }
    }
}

function notificationDisplay(notificationObject) {
    if (notificationObject.isNotificationRead === false && notificationObject.isActionable === true && notificationObject.actedByUserId === null) {
        notificationObject.backgroundColor = 'bgPrimaryLighter'
        notificationObject.color = 'colPrimary'
        notificationObject.iconName = 'star'
        notificationObject.imageVisibility = 'visible'
            //  notificationObject.actionStatus = 'Please Act'
        notificationObject.actionStatus = ' '
    } else if (notificationObject.isNotificationRead === true && notificationObject.isActionable === true && notificationObject.actedByUserId === null) {
        notificationObject.backgroundColor = 'bgGrey94'
        notificationObject.color = 'colGrey40'
        notificationObject.iconName = 'star'
        notificationObject.imageVisibility = 'hidden'
            //  notificationObject.actionStatus = 'Action is still pending'
        notificationObject.actionStatus = ' '
    } else if (notificationObject.isNotificationRead === true && notificationObject.isActionable === true && notificationObject.actedByUserId !== null) {
        notificationObject.backgroundColor = 'bgWhite'
        notificationObject.color = 'colGrey40'
        notificationObject.iconName = 'check'
        notificationObject.imageVisibility = 'hidden'
        notificationObject.actionStatus = 'is succefully completed'
    } else if (notificationObject.isNotificationRead === false && notificationObject.isActionable === false) {
        notificationObject.backgroundColor = 'bgPrimaryLighter'
        notificationObject.color = 'colPrimary'
        notificationObject.iconName = 'eye'
        notificationObject.imageVisibility = 'visible'
        notificationObject.actionStatus = ' '
    } else if (notificationObject.isNotificationRead === true && notificationObject.isActionable === false) {
        notificationObject.backgroundColor = 'bgWhite'
        notificationObject.color = 'colGrey40'
        notificationObject.iconName = 'eye'
        notificationObject.imageVisibility = 'hidden'
        notificationObject.actionStatus = ' '
    }
}
export default { notificationAction, notificationDisplay }