import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message, Select, Divider } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DatePicker from "react-datepicker";
import DisplayMessage from '../../commons/displayMessage.jsx'
import ChartModal from './ChartModal.jsx';

export class Reports extends Component {
   constructor(props) {
      super(props)

      this.state = {
         messageType: '', messageHeader: '', message: [],

         reportOptions: [],
         report: '',
         periodOptions: [
            { key: 'as of', text: 'as of', value: 1 },
            { key: 'for', text: 'for', value: 2 },
         ],
         period: 1,
         month: '',
         year: '',
         date: '',

      }
   }
   componentDidMount() {
      this.onLoad()
   }

   onLoad = () => {
      this.setState({ isFetchingAvailableReports: true })
      ajax.DCNService.fetchAvailableReports().end((err, response) => {
         this.setState({ isFetchingAvailableReports: false })
         utility.handleErrors(err);
         if (!err && response) {
            this.setState({
               reportOptions: response.body.map(item => ({ key: item.reportId, text: item.reportName, value: item.reportId })),
               reportList: response.body
            })
         }
         else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
      });
   }

   handleTextChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }
   handleRadioChange = (name, value) => {
      this.setState({ [name]: value })
   }
   handleDropdownChange = (name, value) => {
      this.setState({ [name]: value })
   }

   render() {
      const dividerStyle = { width: '355px' }
      return (
         <>
            <PageHeader />
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Segment basic className='px-1 pt-0 mt-0'>

               <Grid className="mx-0 px-0 mb-1">
                  <Grid.Column as={Segment} className='p-1'>
                     <div className="textBold textMedium colPrimary mb-1"><Icon name='file alternate' />Standard Reports</div>
                     <Grid columns='equal'>
                        <Grid.Column computer={8} tablet={12} mobile={16}>
                           <Form>
                              <Form.Group widths='equal'>
                                 <Form.Field width={4} inline>
                                    <label>Select Report</label>
                                 </Form.Field>
                                 <Form.Field inline>
                                    <Select fluid required
                                       search selection label='Select Report' placeholder='Select Report'
                                       options={this.state.reportOptions} value={this.state.report}
                                       onChange={(e, { value }) => this.handleDropdownChange('report', value)}
                                    />
                                 </Form.Field>
                              </Form.Group>
                           </Form>
                        </Grid.Column>
                        <Grid.Column computer={2} tablet={4} mobile={16}>
                           <Form>
                              <Form.Group widths='equal'>
                                 {/* <Form.Field width={4} inline>
                                    <label>Select Report</label>
                                 </Form.Field> */}
                                 <Form.Field inline>
                                    <Select fluid required
                                       search selection label='Select Report' placeholder='Select Report'
                                       options={this.state.periodOptions} value={this.state.period}
                                       onChange={(e, { value }) => this.handleDropdownChange('period', value)}
                                    />
                                 </Form.Field>
                              </Form.Group>
                           </Form>
                        </Grid.Column>
                        <Grid.Column>
                           <Form>
                              <Form.Group widths='equal'>
                                 <Form.Field width={2}>
                                    <label>Month</label>
                                 </Form.Field >
                                 <Form.Field inline >
                                    <div className="ui left icon input">
                                       <DatePicker
                                          id='month' placeholderText={`MM/YYYY`} selected={this.state.month || null}
                                          onChange={(date) => this.setState({ month: date })}
                                          dateFormat="MM/yyyy" className="react-dp-custom"
                                          isClearable
                                          strictParsing
                                          maxDate={new Date()}
                                          showMonthYearPicker
                                          showFullMonthYearPicker
                                       />
                                       <i aria-hidden="true" className="calendar outline icon"></i>
                                    </div>
                                    <Button disabled={!(this.state.month && this.state.report)} title='View' icon className='customBtn1Primary mr-0' onClick={() => this.setState({ isOpenChartModal: 'monthReport' })}><Icon name='eye' /></Button>
                                 </Form.Field>
                              </Form.Group>
                              <Divider style={dividerStyle} horizontal>Or</Divider>
                              <Form.Group widths='equal'>
                                 <Form.Field width={2}>
                                    <label>Year</label>
                                 </Form.Field >
                                 <Form.Field inline >
                                    <div className="ui left icon input">
                                       <DatePicker
                                          id='year' placeholderText={`YYYY`} selected={this.state.year || null}
                                          onChange={(date) => this.setState({ year: date })}
                                          dateFormat="yyyy" className="react-dp-custom"
                                          isClearable
                                          strictParsing
                                          maxDate={new Date()}
                                          showYearPicker
                                       />
                                       <i aria-hidden="true" className="calendar outline icon"></i>
                                    </div>
                                    <Button disabled={!(this.state.year && this.state.report)} title='View' icon className='customBtn1Primary mr-0' onClick={() => this.setState({ isOpenChartModal: 'yearReport' })}><Icon name='eye' /></Button>
                                 </Form.Field>
                              </Form.Group>
                              {/* <Divider style={dividerStyle} horizontal>Or</Divider>
                              <Form.Group widths='equal'>
                                 <Form.Field width={2}>
                                    <label>Date</label>
                                 </Form.Field >
                                 <Form.Field inline >
                                    <div className="ui left icon input">
                                       <DatePicker
                                          id='date' placeholderText={`DD/MM/YYYY`} selected={this.state.date || null}
                                          onChange={(date) => this.setState({ date: date })}
                                          dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                          isClearable
                                          strictParsing
                                          maxDate={new Date()}
                                       />
                                       <i aria-hidden="true" className="calendar outline icon"></i>
                                    </div>
                                    <Button disabled={!(this.state.date && this.state.report)} title='Run' icon className='customBtn1Primary mr-0' onClick={() => this.setState({ isOpenChartModal: 'dateReport' })}><Icon name='play' /></Button>
                                 </Form.Field>
                              </Form.Group> */}
                           </Form>
                        </Grid.Column>
                     </Grid>



                  </Grid.Column>
               </Grid>
            </Segment>

            {
               this.state.isOpenChartModal &&
               <ChartModal
                  context={this}
                  // chartCode={this.state.chartCode}
                  isOpen={this.state.isOpenChartModal ? true : false}
                  onClose={() => this.setState({ isOpenChartModal: false })}
               />
            }
         </>
      )
   }
}

export default Reports
