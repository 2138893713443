import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import DisplayMessage from '../../commons/displayMessage.jsx'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import utility from '../../commons/utility.js';

export class ForgotUsername extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageType: '', messageHeader: '', message: [],

            emailId: '',
            error: {},
            isSubmitting: false,
        };

    }

    componentDidMount() {
        // this.onLoad()
    }


    handleTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    validateEmailId = () => {
        let error = ''
        const value = this.state.emailId
        // if (!value) error = t('requiredValidationRule', { what: t('emailId') })
        if (!value) error = 'Email Id is required'
        else if (value && !utility.isEmailValid(value)) error = 'Enter a valid email. eg: example@mail.com';

        this.setState(prevState => ({ error: ({ ...prevState.error, emailId: error }) }))
        return error
    }

    submit = () => {
        let self = this
        let Vo = {
            emailId: this.state.emailId.trim(),
        }
        if (this.validateEmailId()) { }
        else {
            utility.loadingModalLoader(this, "Please wait. submitting the request...");
            this.setState({ isSubmitting: true })
            ajax.OrganizationMaster.recoverUsername(Vo).end((err, response) => {
                this.setState({ isSubmitting: false })
                utility.unLoadingModalLoader(this);
                let errJson = utility.handleErrors(err);
                if (!err && response) {
                    if (response.body)
                        utility.loadDisplayMessage(this, "success", "The request is processed. You will receive an email with instructions.", ['Follow the instructions provided in the mail to recover the username.']);
                    this.setState({ emailId: "" });
                } else {
                    utility.loadDisplayMessage(this, "error", "Something went wrong.", errJson.errMessage);
                }
            });
        }
    }




    render() {
        const { t } = this.props
        return (
            <>
                <MenuHeader />
                <Segment basic className='pt-0 px-2 my-0' style={{ minHeight: 'calc(100vh - 174px)' }}>
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

                    <Grid columns={1} centered>
                        <Grid.Column computer={6} tablet={10} mobile={16}>
                            <Segment>
                                <div className='textBig colPrimary textBold'>{t('forgotUsername')}</div>
                                <Form>
                                    <Form.Field >
                                        <Form.Input className='mb-0' name='emailId' fluid value={this.state.emailId} onChange={(e) => this.handleTextChange(e)} fluid label={t('emailId')} placeholder={t('enterEmailId')} onBlur={() => this.validateEmailId()} required />
                                        {this.state.error.emailId && <div className='errorText'>{this.state.error.emailId}</div>}
                                    </Form.Field>
                                    <Form.Field >
                                        <div className='textSmall my-1'>
                                            <strong>Disclaimer : </strong> Please note that you will receive an email with instructions on what to do next when you press the Submit button.
                                        </div>
                                    </Form.Field>
                                </Form>

                                <Grid>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button fluid className='customBtn1Primary mr-0' loading={this.state.isSubmitting} onClick={() => this.submit()} >{t('submit')}</Button>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid>


                </Segment>

                <Footer />
            </>
        )
    }
}

export default withTranslation()(withRouter(ForgotUsername))
