import React, { Component } from 'react'
import { Button, Dropdown, Form, Grid, Icon, Input, Menu, Modal, Segment } from 'semantic-ui-react';
import { Link, withRouter } from "react-router-dom";
import ajax from '../../commons/agent.js'
import { withTranslation } from "react-i18next";
import utility from '../../commons/utility.js'
import Plot from 'react-plotly.js';
import CustomAccordion from '../reusable/CustomAccordion.jsx';
import DisplayMessage from '../../commons/displayMessage.jsx'
import RenderTable from '../reusable/RenderTable.jsx';

export class RenderPlotlyChart extends Component {
   constructor(props) {
      super(props)
      this.state = {
         messageType: '', messageHeader: '', message: [],

         datasetCode: '',
         chartType: 'Table',
         chartData: '',
         columnList: [],
         ...this.barChartVo,

         isOpenSaveChartModal: false,
         chartName: '',
         chartDescription: '',
         error: {}
      }
   }

   barChartVo = {
      barXAxisName: '',
      barYAxisName: '',
      barTitle: '',
      barXLabel: '',
      barYLabel: '',
      barColor: '',
      barOrientation: '',
      barBarmode: '',
      barAnimationFrame: '',
   }
   scatterChartVo = {
      scatterXAxisName: '',
      scatterYAxisName: '',
      scatterTitle: '',
      scatterXLabel: '',
      scatterYLabel: '',
      scatterColor: '',
      scatterMarkerSize: '',
      scatterSymbol: '',
      scatterAnimationFrame: '',
   }

   componentDidMount() {
      this.onLoad()
   }

   onLoad = () => {
      if (this.props.viewMode && this.props.data) {
         this.intializeChart()
      }
   }
   intializeChart = () => {
      let data = this.props.data
      let newState = {
         pipelineCode: data.pipelineCode,
         stepCode: data.stepCode,
         chartCode: data.chartCode,
         chartName: data.chartName,
         chartDescription: data.description,
         chartType: data.chartType,
         datasetCode: data.datasetCode,
         chartData: JSON.parse(data.chartConfig),
      }
      if (data.chartType === 'Bar Chart') {
         newState = {
            ...newState,
            barXAxisName: data.barChartVo.xAxisName,
            barYAxisName: data.barChartVo.yAxisName,
            barTitle: data.barChartVo.title,
            barXLabel: data.barChartVo.xLabel,
            barYLabel: data.barChartVo.yLabel,
            barColor: data.barChartVo.color,
            barOrientation: data.barChartVo.orientation,
            barBarmode: data.barChartVo.barmode,
            barAnimationFrame: data.barChartVo.animationFrame,
         }
      }
      this.setState({ ...newState })
   }

   resolveDropdownValue = (value, options = []) => {
      return options.length ? options.filter(item => item.value === value)[0]?.text : value
   }

   handleTextChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }

   handleChartTypeChange = (value) => {
      this.setState({ chartType: value })
   }

   handleDatasetChange = (value) => {
      this.setState({ datasetCode: value })
      value && ajax.Analytics.fetchDatasetDetails(value).end((err, response) => {
         utility.handleErrors(err);
         if (!err && response) {
            let columnList = response.body && response.body.attributeVo ? response.body.attributeVo.map(item => ({ key: item.businessMetadataCode, text: item.businessMetadataName, value: item.technicalMetaDataName })) : []
            this.setState({ columnList })
            // if (response.body.errorMessage) {
            //    utility.loadDisplayMessage(this, "error", 'An error occured', [`${response.body.errorMessage}`])
            // }
         }
         else {
            utility.loadDisplayMessage(this, "error", "Something went wrong.", ['Please contact system administrator'])
         }
      })
   }
   showOutput = () => {
      const self = this
      let Vo = {
         chartType: self.state.chartType,
         pipelineCode: self.props.pipelineCode,
         datasetCode: self.state.datasetCode,
         barChartVo: null,
      }
      if (this.state.chartType === 'Bar Chart') {
         Vo = {
            ...Vo,
            barChartVo: {
               xAxisName: self.state.barXAxisName,
               yAxisName: self.state.barYAxisName,
               title: self.state.barTitle,
               xLabel: self.state.barXLabel,
               yLabel: self.state.barYLabel,
               color: self.state.barColor,
               orientation: self.state.barOrientation,
               barmode: self.state.barBarmode,
               animationFrame: self.state.barAnimationFrame
            }
         }
      }
      if (this.state.chartType === 'Scatter Chart') {
         Vo = {
            ...Vo,
            barChartVo: {
               xAxisName: this.state.scatterXAxisName,
               yAxisName: this.state.scatterYAxisName,
               title: this.state.scatterTitle,
               xLabel: this.state.scatterXLabel,
               yLabel: this.state.scatterYLabel,
               color: this.state.scatterColor,
               markerSize: this.state.scatterMarkerSize,
               symbol: this.state.scatterSymbol,
               animationFrame: this.state.scatterAnimationFrame,
            }
         }
      }
      self.setState({ fetchingChart: true })
      ajax.Analytics.getPlotlyJsonData(Vo).end((err, response) => {
         self.setState({ fetchingChart: false })
         utility.handleErrors(err);
         if (!err && response) {
            if (response.body.errorMessage) {
               utility.loadDisplayMessage(self, "error", 'An error occured', [`${response.body.errorMessage}`])
            }
            else {
               self.setState({ chartData: JSON.parse(response.body.status) })
            }
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator'])
         }
      })
   }
   validateChartName = () => {
      let error = ''
      const value = this.state.chartName.trim()
      if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('chartName') })
      else if (value.length <= 3) error = 'Length should be more than 3 characters'
      else if (value.length >= 30) error = 'Length should be less than 30 characters'

      this.setState(prevState => ({ error: ({ ...prevState.error, chartName: error }) }))
      return error
   }
   validateChartDescription = () => {
      let error = ''
      const value = this.state.chartDescription.trim()
      if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('chartDescription') })
      else if (value.length <= 5) error = 'Length should be more than 5 characters'
      else if (value.length >= 250) error = 'Length should be less than 250 characters'

      this.setState(prevState => ({ error: ({ ...prevState.error, chartDescription: error }) }))
      return error
   }
   validateAll = () => {
      this.validateChartName()
      this.validateChartDescription()

      return (this.validateChartName() || this.validateChartDescription())
   }

   saveChart = () => {
      let context = this.props.CreatePipelineContext
      let Vo = {
         chartType: this.state.chartType,
         pipelineCode: this.props.pipelineCode,
         datasetCode: this.state.datasetCode,
         stepCode: context.state.activeStep.stepCode,
         chartCode: '',
         description: this.state.chartDescription,
         chartName: this.state.chartName,
         chartConfig: JSON.stringify(this.state.chartData),
         barChartVo: null,
      }
      if (this.state.chartType === 'Bar Chart') {
         Vo = {
            ...Vo,
            barChartVo: {
               xAxisName: this.state.barXAxisName,
               yAxisName: this.state.barYAxisName,
               title: this.state.barTitle,
               xLabel: this.state.barXLabel,
               yLabel: this.state.barYLabel,
               color: this.state.barColor,
               orientation: this.state.barOrientation,
               barmode: this.state.barBarmode,
               animationFrame: this.state.barAnimationFrame,
            }
         }
      }

      if (this.validateAll()) { }
      else {
         this.setState({ isSavingChart: true })
         ajax.Analytics.savePlotlyChart(Vo).end((err, response) => {
            this.setState({ isSavingChart: false })
            utility.handleErrors(err);
            if (!err && response) {
               this.setState({ isOpenSaveChartModal: false, chartName: '', chartDescription: '' })
               if (response.body.errorMessage) {
                  utility.loadDisplayMessage(this, "error", 'An error occured', [`${response.body.errorMessage}`])
               }
            }
            else {
               utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator'])
            }
         })
      }
   }

   render() {
      const { datasetList, CreatePipelineContext, viewMode } = this.props
      const t = this.props.t
      const chartTypes = [
         { name: 'Table', icon: 'table', isDisabled: false },
         { name: 'Bar Chart', icon: 'chart bar', isDisabled: false },
         { name: 'Pie Chart', icon: 'chart pie', isDisabled: true },
         { name: 'Line Chart', icon: 'chart line', isDisabled: true },
         { name: 'Stacked Area Chart', icon: 'chart area', isDisabled: true },
         { name: 'Scatter Chart', icon: 'spinner', isDisabled: false },
         { name: 'Bubble Chart', icon: 'circle thin', isDisabled: true },
         { name: 'Volume-leaflet', icon: 'leaf', isDisabled: true },
      ]
      const orientationOptions = [{ key: 'horizontal', text: 'Horizontal', value: 'h' }, { key: 'vertical', text: 'Vertical', value: 'v' }]
      const barModeOptions = [{ key: 'overlay', text: 'Overlay', value: 'overlay' }, { key: 'group', text: 'Group', value: 'group' }, { key: 'relative', text: 'Relative', value: 'relative' }]
      return (
         <>
            <div style={{ minHeight: '400px' }} >
               <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
               {!viewMode &&
                  <Grid>
                     <Grid.Column computer={8} tablet={12} mobile={16} verticalAlign='middle' className='py-5px'>
                        <div className='textBold textSmall'>Select Dataset</div>
                        <Form size='small'>
                           <Form.Field>
                              <Dropdown fluid clearable selection search scrolling placeholder='Select Dataset' options={datasetList || []} value={this.state.datasetCode} onChange={(e, { value }) => this.handleDatasetChange(value)} />
                           </Form.Field>
                        </Form>
                     </Grid.Column>
                     {this.state.datasetCode && <>
                        <Grid.Column computer={8} tablet={4} mobile={16} textAlign='right' verticalAlign='bottom' className='py-5px'>
                           <Button className='customBtn1Primary' disabled={!this.state.chartData} onClick={() => this.setState({ isOpenSaveChartModal: true })}>{t('saveChart')}</Button>
                           <Button className='customBtn1Primary mr-0' loading={this.state.fetchingChart} onClick={this.showOutput}>{t('apply')}</Button>
                        </Grid.Column>
                     </>}
                  </Grid>
               }
               {(this.state.datasetCode || viewMode) && <>
                  {!viewMode &&
                     <Menu icon size='small' className='tab-wrapped noShadow'>
                        {chartTypes.map(chartType =>
                           <Menu.Item
                              key={chartType.name}
                              name={chartType.name}
                              className={this.state.chartType === chartType.name ? 'colPrimary bgPrimaryLighter' : ''}
                              active={this.state.chartType === chartType.name}
                              onClick={() => this.handleChartTypeChange(chartType.name)}
                              disabled={chartType.isDisabled}
                              title={chartType.name}
                           >
                              <Icon name={chartType.icon} />
                              {/* <span className='ml-5px'> {chartType.name}</span> */}
                           </Menu.Item>
                        )}
                     </Menu>
                  }
                  {this.state.chartType === 'Bar Chart' && <Segment basic className='bgGrey98 border-1 border-rad'>
                     <Form size='small'>
                        <Form.Group>
                           <Form.Field width={4}>
                              <label>x-axis</label>
                              {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barXAxisName, this.state.columnList)} /> :
                                 <Dropdown fluid clearable selection search scrolling placeholder='x-axis' options={this.state.columnList} value={this.state.barXAxisName} onChange={(e, { value }) => this.setState({ barXAxisName: value })} />}
                           </Form.Field>
                           <Form.Field width={4}>
                              <label>y-axis</label>
                              {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barYAxisName, this.state.columnList)} /> :
                                 <Dropdown fluid clearable selection search scrolling placeholder='y-axis' options={this.state.columnList} value={this.state.barYAxisName} onChange={(e, { value }) => this.setState({ barYAxisName: value })} />}
                           </Form.Field>
                        </Form.Group>
                        <CustomAccordion head={{ title: 'Advanced Settings', class: 'textNormal colPrimary py-5px' }} isShrink={'true'} >
                           <Form.Group>
                              <Form.Field width={4}>
                                 <label>Title</label>
                                 <Input readOnly={viewMode} name='barTitle' value={this.state.barTitle} onChange={(e) => this.handleTextChange(e)} placeholder={'Title'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Label x</label>
                                 <Input readOnly={viewMode} name='barXLabel' value={this.state.barXLabel} onChange={(e) => this.handleTextChange(e)} placeholder={'Label x'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Label y</label>
                                 <Input readOnly={viewMode} name='barYLabel' value={this.state.barYLabel} onChange={(e) => this.handleTextChange(e)} placeholder={'Label y'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Color</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barColor, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Color' options={this.state.columnList} value={this.state.barColor} onChange={(e, { value }) => this.setState({ barColor: value })} />}
                              </Form.Field>
                           </Form.Group>
                           <Form.Group>
                              <Form.Field width={4}>
                                 <label>Orientation</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barOrientation, orientationOptions)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Orientation' options={orientationOptions} value={this.state.barOrientation} onChange={(e, { value }) => this.setState({ barOrientation: value })} />}
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Bar Mode</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barBarmode, barModeOptions)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Bar Mode' options={barModeOptions} value={this.state.barBarmode} onChange={(e, { value }) => this.setState({ barBarmode: value })} />}
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Animation Frame</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.barAnimationFrame, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Animation Frame' options={this.state.columnList} value={this.state.barAnimationFrame} onChange={(e, { value }) => this.setState({ barAnimationFrame: value })} />}
                              </Form.Field>
                           </Form.Group>
                        </CustomAccordion>
                     </Form>
                  </Segment>}

                  {this.state.chartType === 'Scatter Chart' && <Segment basic className='bgGrey98 border-1 border-rad'>
                     <Form size='small'>
                        <Form.Group>
                           <Form.Field width={4}>
                              <label>x-axis</label>
                              {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterXAxisName, this.state.columnList)} /> :
                                 <Dropdown fluid clearable selection search scrolling placeholder='x-axis' options={this.state.columnList} value={this.state.scatterXAxisName} onChange={(e, { value }) => this.setState({ scatterXAxisName: value })} />}
                           </Form.Field>
                           <Form.Field width={4}>
                              <label>y-axis</label>
                              {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterYAxisName, this.state.columnList)} /> :
                                 <Dropdown fluid clearable selection search scrolling placeholder='y-axis' options={this.state.columnList} value={this.state.scatterYAxisName} onChange={(e, { value }) => this.setState({ scatterYAxisName: value })} />}
                           </Form.Field>
                        </Form.Group>
                        <CustomAccordion head={{ title: 'Advanced Settings', class: 'textNormal colPrimary py-5px' }} isShrink={'true'} >
                           <Form.Group>
                              <Form.Field width={4}>
                                 <label>Title</label>
                                 <Input readOnly={viewMode} name='scatterTitle' value={this.state.scatterTitle} onChange={(e) => this.handleTextChange(e)} placeholder={'Title'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Label x</label>
                                 <Input readOnly={viewMode} name='scatterXLabel' value={this.state.scatterXLabel} onChange={(e) => this.handleTextChange(e)} placeholder={'Label x'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Label y</label>
                                 <Input readOnly={viewMode} name='scatterYLabel' value={this.state.scatterYLabel} onChange={(e) => this.handleTextChange(e)} placeholder={'Label y'} fluid />
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Color</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterColor, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Color' options={this.state.columnList} value={this.state.scatterColor} onChange={(e, { value }) => this.setState({ scatterColor: value })} />}
                              </Form.Field>
                           </Form.Group>
                           <Form.Group>
                              <Form.Field width={4}>
                                 <label>Marker size</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterMarkerSize, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Marker size' options={this.state.columnList} value={this.state.scatterMarkerSize} onChange={(e, { value }) => this.setState({ scatterMarkerSize: value })} />}
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Symbol</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterSymbol, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Symbol' options={this.state.columnList} value={this.state.scatterSymbol} onChange={(e, { value }) => this.setState({ scatterSymbol: value })} />}
                              </Form.Field>
                              <Form.Field width={4}>
                                 <label>Animation Frame</label>
                                 {viewMode ? <Input readOnly value={this.resolveDropdownValue(this.state.scatterAnimationFrame, this.state.columnList)} /> :
                                    <Dropdown fluid clearable selection search scrolling placeholder='Animation Frame' options={this.state.columnList} value={this.state.scatterAnimationFrame} onChange={(e, { value }) => this.setState({ scatterAnimationFrame: value })} />}
                              </Form.Field>
                           </Form.Group>
                        </CustomAccordion>
                     </Form>
                  </Segment>}

                  <Segment style={{ minHeight: '320px' }} className={`${this.state.fetchingChart ? '' : 'p-0 overflow-auto'}`} textAlign='center' basic padded={this.state.fetchingChart ? 'very' : false} loading={this.state.fetchingChart}>
                     {this.state.chartData ? <>
                        {this.state.chartType === 'Table' &&
                           <RenderTable
                              columns={
                                 Object.keys(this.state.chartData[0]).map(item => (
                                    {
                                       key: item,
                                       title: item,
                                       textAlign: isNaN(this.state.chartData[0][item]) ? 'left' : 'right'
                                    }
                                 ))
                              }
                              rows={this.state.chartData}
                              tableProps={{ celled: true, compact: true }}
                           />
                        }
                        {/* {this.state.chartType === 'Map Chart' &&
                           <RenderBokehChart
                              id='bokeh1'
                              data={this.state.chartData}
                           />
                        } */}
                        {this.state.chartType !== 'Table' && this.state.chartType !== 'Map Chart' &&
                           <Plot
                              data={this.state.chartData.data}
                              layout={this.state.chartData.layout}
                           />
                        }
                     </>
                        :
                        <div className='mt-1'></div>
                     }
                  </Segment>

               </>
               }
            </div>
            <Modal
               open={this.state.isOpenSaveChartModal}
               centered={false}
               onClose={() => this.setState({ isOpenSaveChartModal: false })}
               dimmer='inverted'
               size='tiny'
            >
               <Modal.Content>
                  <Form size='small'>
                     <Form.Input name='chartName' label='Chart Name' placeholder='Chart Name' value={this.state.chartName} onChange={(e) => this.handleTextChange(e)} onBlur={() => this.validateChartName()} fluid required />
                     {this.state.error.chartName && <div className='errorText'><p>{this.state.error.chartName}</p> </div>}
                     <Form.TextArea name='chartDescription' label={t('chartDescription')} placeholder={t('chartDescription')} value={this.state.chartDescription} onChange={(e) => this.handleTextChange(e)} onBlur={() => this.validateChartDescription()} required />
                     {this.state.error.chartDescription ? <div className='errorText'>{this.state.error.chartDescription}</div> : <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '5', to: '250' })}</div>}
                  </Form>
               </Modal.Content>
               <Modal.Actions>
                  <Button className='customBtn1Secondary' loading={this.state.isSavingChart} onClick={() => this.setState({ isOpenSaveChartModal: false })} >{t('cancel')}</Button>
                  <Button className='customBtn1Primary' loading={this.state.isSavingChart} onClick={() => this.saveChart()}>{t('save')}</Button>
               </Modal.Actions>
            </Modal >
         </>
      )
   }
}

export default withTranslation()(withRouter(RenderPlotlyChart))

