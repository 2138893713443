import React, { Component } from "react";
import { Accordion, Header, Grid, Icon, Card, List, Image, Input, Segment, Button, Dropdown } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import "../css/dashboard.css";
import utility from '../../commons/utility.js'
import PageHeader from "../PageHeader.jsx";
import OrganizationCard from "../cards/OrganizationCard.jsx";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'

class PartnerListing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0, data: null,
      isFetchingCategories: false,
      orgNameSearchText: '',
      categoryFilterArr: null,
    }
  }
  componentDidMount() {
    this.onLoad()
  }
  onLoad = () => {
    this.setState({ data: this.props.fetchOrganizationList() })

    this.setState({ isFetchingCategories: true })
    ajax.OrganizationMaster.listCategories().end((err, res) => {
      this.setState({ isFetchingCategories: false })
      if (!err && res) {
        this.setState({ categoryList: res.body.map(item => ({ key: item.orgCategoryId, text: item.orgCategoryName, value: item.orgCategoryName })) })
      }
    })
  }



  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSearch = (dataArr, searchText, attr) => {
    if (dataArr && searchText) {
      return dataArr.filter(item => item[attr] && item[attr].toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    }
    return dataArr
  }
  handleFilter = (dataArr, filterArr, attr) => {
    if (dataArr && filterArr && filterArr.length > 0) {
      return dataArr.filter(item => filterArr.includes(item[attr] && item[attr].toString()))
    }
    return dataArr
  }
  handleReset = () => {
    this.setState({
      orgNameSearchText: '',
      categoryFilterArr: null,
    })
  }

  render() {
    const { t } = this.props

    let newData = this.handleSearch(this.state.data, this.state.orgNameSearchText, 'organizationName')
    newData = this.handleFilter(newData, this.state.categoryFilterArr, 'organizationCategory')
    const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())
    const isHost = (orgCategory === 'Improvement Service')
    return (
      <>
        <PageHeader />
        <Segment basic className='pt-0 px-1'>
        <Grid as={Segment} className='mt-0'>
            <Grid.Column computer={4} tablet={8} mobile={16} className="boxShadow-0" verticalAlign='middle'>
              <div className="textBig colPrimary">{this.state.data && this.state.data.length} <span className="colGrey40">{t('partners')}</span></div>
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16} className="boxShadow-0 text-right" verticalAlign='middle'>
              {isHost && <Button icon='list alternate outline' content={t('approvals')} className='customBtn1Primary' compact size='small' onClick={() => this.props.history.push('/dashboard/approvals')} />}
              {isHost && <Button icon='add' content={t('registerOrganisation')} className='customBtn1Primary' compact size='small' onClick={() => this.props.history.push('/dashboard/registerOrganisation')} />}
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16} className="boxShadow-0 text-right">
              <Dropdown
                fluid
                multiple loading={this.state.isFetchingCategories}
                onChange={(e, { value }) => this.setState({ categoryFilterArr: value })}
                options={this.state.categoryList}
                placeholder={t('category')} selection value={this.state.categoryFilterArr}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16} className="boxShadow-0 text-right">
              <Input fluid placeholder={t('searchPartners')} icon='search' value={this.state.orgNameSearchText} onChange={(e) => this.setState({ orgNameSearchText: e.target.value })} />
            </Grid.Column>
            {/* <Grid.Column computer={2} tablet={4} mobile={16} className="boxShadow-0 text-right">
                <Button className='customBtn1Secondary' fluid onClick={() => this.handleReset()}>{t('reset')}</Button>
              </Grid.Column> */}
          </Grid>


          <Grid columns='equal' className='pb-5px'>
            {
              this.state.data && newData.map((item, key) => {
                return (
                  <OrganizationCard
                    key={key} data={item}
                  />
                )
              })
            }
          </Grid>
        </Segment>

      </>
    );
  }
}


export default withTranslation()(withRouter(PartnerListing))