import React, { Component } from "react";
import { render } from "react-dom";
import { withTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

class PartnerDetailsTabs extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props

    const panes = [
      {
        menuItem: `${t('dataProducts')}`,
        render: () => (
          <Tab.Pane attached={'bottom'}>
            {/* <PartnersDataProducts bpid={this.props.bpid} fetchOrganizationName={this.props.fetchOrganizationName} fetchSubCategoryName={this.props.fetchSubCategoryName} fetchCategoryName={this.props.fetchCategoryName} /> */}
          </Tab.Pane>
        )
      },
      {
        menuItem: `${t('wants')}`,
        render: () => (
          <Tab.Pane attached={'bottom'}>
            {/* <PartnersWants bpid={this.props.bpid} fetchOrganizationName={this.props.fetchOrganizationName} fetchSubCategoryName={this.props.fetchSubCategoryName} fetchCategoryName={this.props.fetchCategoryName} /> */}
          </Tab.Pane>
        )
      }
    ];
    return (
      <>
        <Tab
          menu={{ attached: 'top', size: 'small', className: "tab-wrapped" }}
          panes={panes}
          className="tabMenu"
        />
      </>


    )
  }
}

export default withTranslation()(PartnerDetailsTabs);
