/*
 * The mainHeader component is the parent for our UI and manages the header and main menu structure of the UI
 */

import React, { Component } from 'react';
import { Feed, Image, Dropdown, Divider, Icon, List, Button, Loader, Segment, Message } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
// import orderDetails from '../orderDetails.js'
import 'semantic-ui-css/semantic.min.css';
import ajax from '../../commons/agent.js';
import utility from '../../commons/utility.js';
import Notification from './notifications.js';
import commonCss from '../css/commonCss.js';

function NotificationHeader() {
    let font = Object.assign({}, commonCss.fontSizeAccurate, commonCss.fontFamily, commonCss.fontColor, commonCss.fontWeight)
    return (<Feed style={Object.assign({}, font, commonCss.header, { padding: '3%', margin: '0 0 5px 0' })}>Notifications
    </Feed>)
}

class TopNotificationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { notificationData: [], notifications: [], errorResponse: [], showFYA: true, markAllReadLoading: false };
        this.fetchLatestNotifications = this.fetchLatestNotifications.bind(this)
    }
    componentDidMount() {
        this.fetchLatestNotifications()
        setInterval(this.fetchLatestNotifications, 60000);
    }
    fetchLatestNotifications() {
        var self = this;
        ajax.Notifications.fetchLatestTenNotifications().end((err, response) => {
            var temp = utility.handleErrors(err);
            if (!err && response) {
                for (let i = 0; i < response.body.length; i++) {
                    Notification.notificationDisplay(response.body[i])
                }
                let noti = utility.dataConversion(response.body, 'createdAt', '', '')
                let noti2 = this.state.showFYA ? noti.filter(item => item.isActionable) : noti.filter(item => !item.isActionable)
                self.setState({ notificationConst: noti, notifications: noti2 });
            }
            else {
                this.setState({ errorResponse: temp });
            }
        });
    }
    notificationAction(notification, e) {
        this.props.fetchNotificationCount()
        // this.fetchLatestNotifications()
        Notification.notificationAction(notification, this.props.context, this.fetchLatestNotifications)
    }
    markAllRead() {
        var self = this;
        this.setState({ markAllReadLoading: true });
        ajax.Notifications.markAllRead().end((err, response) => {
            var temp = utility.handleErrors(err);
            if (!err && response) {
                this.fetchLatestNotifications()
                this.props.fetchNotificationCount()
                this.setState({ markAllReadLoading: false });
            }
            else {
                this.setState({ errorResponse: temp, markAllReadLoading: false });
            }
        });
    }
    render() {
        var data = this.state.notifications
        var self = this

        return (
            <>
                <div className='border-b' style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 1px 4px 5px' }}>
                    <span style={{ padding: '4px 2px 2px 2px' }} className='textBold textNormal colGrey20'><Icon name='bell' className='m-0' />Notifications</span>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button title='Action' fluid className={this.state.showFYA ? 'customBtn1Primary py-5px' : 'customBtn1Secondary py-5px'} primary compact size='small' onClick={(e) => { e.stopPropagation(); this.setState({ showFYA: true, notifications: this.state.notificationConst && this.state.notificationConst.filter(item => item.isActionable) }) }}>Action</Button>
                        <Button title='Info' fluid className={!this.state.showFYA ? 'customBtn1Primary py-5px' : 'customBtn1Secondary py-5px'} primary compact size='small' onClick={(e) => { e.stopPropagation(); this.setState({ showFYA: false, notifications: this.state.notificationConst && this.state.notificationConst.filter(item => !item.isActionable) }) }}>Info</Button>
                    </div>
                </div>
                {!this.state.showFYA &&
                    <div className='border-b' style={{ display: 'flex', justifyContent: 'flex-end', padding: '2px 6px' }}>
                        <span className='customLink textSmall textThin' onClick={() => this.markAllRead()}><Loader active={this.state.markAllReadLoading} inline size='mini' /> Mark All as Read</span>
                    </div>
                }
                <Segment basic loading={this.state.markAllReadLoading} className='p-0 m-0' style={{ overflowY: 'auto', maxHeight: '60vh', minWidth: '400px' }}>
                    {data && data.length > 0 ?
                        <List className='m-0 py-5px border-b'>                            {

                            data.map(function (item, index) {
                                // let notificationHeader = " " + item.objectType + " " + item.objectCode + " " + item.actionVerb + " " + item.actionStatus + " "
                                let notificationHeader2 = " " + item.objectType + " " + item.actionVerb + " " + item.actionStatus + " "

                                return (

                                    <List.Item onClick={self.notificationAction.bind(self, item)} className={`p-5px ${item.backgroundColor}`} style={{ marginBottom: '1px' }} key={item.notificationId}>
                                        <List.Content>
                                            <a>
                                                <List.Description>
                                                    <Icon name={item.iconName} className={`${item.color}`} />
                                                    <span className={`${item.color}`}>{notificationHeader2}</span>
                                                </List.Description>
                                            </a>
                                        </List.Content>
                                        <List.Description className='colGrey40 textSmaller' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ marginLeft: '33px' }}>
                                                {/* {item.objectCode} */}
                                            </span>
                                            <span></span>{utility.timeAgo(item.createdAt)}
                                        </List.Description>
                                    </List.Item>
                                )
                            })}
                        </List>
                        :
                        <div className='p-1 text-center border-b'>Empty</div>
                    }
                </Segment>
            </>
        );
    }
}
export default { TopNotificationsList, NotificationHeader };
