import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Table, Tab, Grid, Segment, Icon, Modal, Button, Divider, Menu, Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DisplayMessage from '../../commons/displayMessage.jsx'
import RenderPlotlyChart from './RenderPlotlyChart.jsx';
import moment from 'moment'


export class ChartModal extends Component {
   constructor(props) {
      super(props)

      this.state = {
         messageType: '', messageHeader: '', message: [],
         data: ''
      }

   }
   componentDidMount() {
      this.onLoad()
   }

   onLoad = () => {
      this.setState({ requestingReport: true })
      ajax.DCNService.requestReport(this.getVo()).end((err, response) => {
         this.setState({ requestingReport: false })
         if (!err && response) {
            this.fetchChart()
            let fetchChartIntervalId = setInterval(this.fetchChart, 5000)
            this.setState({ fetchChartIntervalId: fetchChartIntervalId })
         }
         else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
      })
   }

   fetchChart = () => {

      this.setState({ isFetchingChartCode: true })
      ajax.DCNService.getChartCode(this.getVo()).end((err, response) => {
         if (!err && response) {
            if (response.body.status === 'Ready') {
               this.setState({ isFetchingChartCode: false })
               clearInterval(this.state.fetchChartIntervalId)
               
               let context = this.props.context
               let fromDate = ''
               let toDate = ''
               let titleDate = ''
               let titlePeriod = context.state.period === 1 ? 'as of' : 'for'
               if (context.state.isOpenChartModal === 'monthReport') {
                  fromDate = moment(context.state.month).format('YYYY-MM-DD')
                  toDate = moment(context.state.month).add(1, 'months').format('YYYY-MM-DD')
                  titleDate = moment(context.state.month).format('MMMM') + ' ' + moment(context.state.month).format('YYYY')
               }
               else if (context.state.isOpenChartModal === 'yearReport') {
                  fromDate = moment(context.state.year).format('YYYY-MM-DD')
                  toDate = moment(context.state.year).add(1, 'years').format('YYYY-MM-DD')
                  titleDate = moment(context.state.year).format('YYYY')
               }
               else if (context.state.isOpenChartModal === 'dateReport') {
                  fromDate = moment(context.state.date).format('YYYY-MM-DD')
                  toDate = moment(context.state.date).add(1, 'days').format('YYYY-MM-DD')
                  titleDate = moment(context.state.date).format('YYYY-MM-DD')
               }
               let Vo = {
                  "chartCode": response.body.chartCode,
                  "reportId": response.body.reportId,
                  "fromDate": context.state.period === 1 ? null : fromDate,
                  "effectiveDate": toDate,
                  "period": context.state.period,

               }

               this.setState({ isFetchingChart: true })
               ajax.DCNService.getChartDetailsByChartCode(Vo).end((err, response) => {
                  this.setState({ isFetchingChart: false })
                  if (!err && response) {
                     this.setState({ data: response.body })
                     if (response.body.errorMessage) {
                        utility.loadDisplayMessage(this, "error", 'An error occured', [`${response.body.errorMessage}`])
                     }
                  }
                  else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
               });
            }
            else if (response.body.status === 'Failure') {
               this.setState({ isFetchingChartCode: false })
               clearInterval(this.state.fetchChartIntervalId)

               utility.loadDisplayMessage(this, "error", "Something went wrong.", [response.body.message])
            }
         }
         else {
            this.setState({ isFetchingChartCode: false })
            clearInterval(this.state.fetchChartIntervalId)

            utility.loadDisplayMessage(this, "error", "Something went wrong.", [])
         }
      })
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.isOpenChartModal !== this.props.isOpenChartModal) {
         this.onLoad()
      }
   }

   componentWillUnmount() {
      clearInterval(this.state.fetchChartIntervalId)
   }


   getVo = () => {
      let context = this.props.context
      let fromDate = ''
      let toDate = ''
      let titleDate = ''
      let titlePeriod = context.state.period === 1 ? 'as of' : 'for'
      if (context.state.isOpenChartModal === 'monthReport') {
         fromDate = moment(context.state.month).format('YYYY-MM-DD')
         toDate = moment(context.state.month).add(1, 'months').format('YYYY-MM-DD')
         titleDate = moment(context.state.month).format('MMMM') + ' ' + moment(context.state.month).format('YYYY')
      }
      else if (context.state.isOpenChartModal === 'yearReport') {
         fromDate = moment(context.state.year).format('YYYY-MM-DD')
         toDate = moment(context.state.year).add(1, 'years').format('YYYY-MM-DD')
         titleDate = moment(context.state.year).format('YYYY')
      }
      else if (context.state.isOpenChartModal === 'dateReport') {
         fromDate = moment(context.state.date).format('YYYY-MM-DD')
         toDate = moment(context.state.date).add(1, 'days').format('YYYY-MM-DD')
         titleDate = moment(context.state.date).format('YYYY-MM-DD')
      }
      let Vo = {
         reportId: context.state.report,
         reportTitle: `${context.state.reportList.filter(item => item.reportId === context.state.report)[0]?.reportName} ${titlePeriod} ${titleDate}`,
         fromDate: context.state.period === 1 ? null : fromDate,
         effectiveDate: toDate,
         reportType: context.state.isOpenChartModal,
         period: context.state.period,
      }
      return Vo
   }


   excelDownload = () => {
      let self = this
      let context = this.props.context
      let Vo = {
         "chartCode": this.state.data.chartCode,
         "reportId": context.state.report
      }
      self.setState({ isDownloadingExcel: true });
      var promise = new Promise((resolve, reject) => {
         ajax.DCNService.downloadReportExcel(Vo).end(async (err, response) => {
            self.setState({ isDownloadingExcel: false });
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               //console.log(response)
               let msg = [];
               resolve(response);
            } else {
               utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }
         })
      }).then((resolve) => {
         var headers = resolve.headers;
         var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(blob);
         link.download = resolve.xhr.getResponseHeader('fileName');
         document.body.appendChild(link);
         link.click();
         this.setState({ btn1loading: false });
      })

   }
   render() {
      const { t, context, isOpen } = this.props
      const chartTypes = [
         { name: 'Table', icon: 'table', isDisabled: false },
         { name: 'Bar Chart', icon: 'chart bar', isDisabled: false },
         { name: 'Pie Chart', icon: 'chart pie', isDisabled: true },
         { name: 'Line Chart', icon: 'chart line', isDisabled: true },
         { name: 'Stacked Area Chart', icon: 'chart area', isDisabled: true },
         { name: 'Scatter Chart', icon: 'spinner', isDisabled: false },
         { name: 'Bubble Chart', icon: 'circle thin', isDisabled: true },
         { name: 'Volume-leaflet', icon: 'leaf', isDisabled: true },
      ]

      let isLoading = this.state.requestingReport || this.state.isFetchingChartCode || this.state.isFetchingChart
      return (
         <>
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Modal
               open={isOpen}
               centered={false}
               dimmer='inverted'
               size='large'
            >
               <Modal.Content className='py-1'>
                  <Segment basic loading={false} padded={isLoading ? 'very' : false} className={`${isLoading ? '' : 'p-0'}`}>
                     {isLoading && <Loader active>
                        {this.state.requestingReport && 'Requesting Report'}
                        {this.state.isFetchingChartCode && 'Generating Report'}
                        {this.state.isFetchingChart && 'Rendering Report'}
                     </Loader>}
                     {this.state.data && <>
                        <div className='flex flexJustifySpaceBetween'>
                           <div className='flex'>
                              <Icon style={{ marginTop: '5px' }} name={chartTypes.filter(item => item.name === this.state.data.chartType)[0].icon} size='large' className='colPrimary' title={this.state.data.chartType} />
                              <div>
                                 <div className='colPrimary textMedium textBold py-5px'>{this.state.data.chartName}</div>
                                 {/* <div className='colGrey40 textSmall mb-5px'>{this.state.data.description}</div> */}
                              </div>
                           </div>
                           <div>
                              <Button size='small' className='customBtn1Secondary' disabled={!this.state.data.chartCode} loading={this.state.isDownloadingExcel} onClick={this.excelDownload}>
                                 <Icon name='file excel' />Download Excel
                              </Button>
                           </div>
                        </div>
                        <RenderPlotlyChart
                           context={this} viewMode={true}
                           data={this.state.data} t={t}
                           pipelineCode={this.state.data.pipelineCode}
                        />
                     </>}
                  </Segment>
                  <Grid>
                     <Grid.Column textAlign='right'>
                        <Button className='customBtn1Secondary' onClick={() => this.props.onClose()}>{t('close')}</Button>
                     </Grid.Column>
                  </Grid>
               </Modal.Content>
            </Modal>
         </>
      )
   }
}

export default withTranslation()(ChartModal)

