import React, { Component } from "react";
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message } from "semantic-ui-react";
import PageHeader from "../PageHeader.jsx";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from "../../commons/agent.js";
import DisplayMessage from "../../commons/displayMessage.jsx";
import Footer from "../footer.jsx";
import MenuHeader from "../navbar.jsx";
import utility from "../../commons/utility.js";
import { sendAuthorizationRequest, sendTokenRequest } from "../Login/actions/sign-in";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageType: "",
      messageHeader: "",
      message: [],

      confirmationKey: "",
      password: "",
      confirmPassword: "",
      showPass: false,
      error: {},
      isSubmitting: false,

      showPass: false,
    };
  }

  componentDidMount() {
    // this.onLoad()
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateConfirmationKey = () => {
    let error = "";
    const value = this.state.confirmationKey;
    // if (!value) error = t('requiredValidationRule', { what: t('confirmationKey') })
    if (!value) error = "Confirmation Key is required";

    this.setState((prevState) => ({ error: { ...prevState.error, confirmationKey: error } }));
    return error;
  };

  validatePassword = () => {
    let error = "";
    const value = this.state.password;
    // if (!value) error = t('requiredValidationRule', { what: t('password') })
    if (!value) error = "Password is required";
    else if (value.length < 12) error = "Password should have minimum 12 characters ";
    else if (value.length > 29) error = "Password should be less than 30 characters";
    else if (value.search(/[a-z]/) < 0) error = "Password should have at least one lower case (a-z)";
    else if (value.search(/[0-9]/) < 0) error = "Password should have at least one number (0-9)";
    else if (!/[$&\(\)*,@\[\]^_{}~]/.test(value)) error = "Password should have at least one special character out of these acceptable characters ($ & ( ) * , @ [ ] ^ _ { } ~)";
    else if (/[!"%+£]/.test(value)) error = 'Password should not include (! " % + or £)';
    else if (/(\r\n|\n|\r|\s)+/.test(value)) error = "Password should not contain spaces or new line characters";

    this.setState((prevState) => ({ error: { ...prevState.error, password: error } }));
    return error;
  };

  validateConfirmPassword = () => {
    let error = "";
    const value = this.state.confirmPassword;
    const passValue = this.state.password;

    // if (!value) error = t('requiredValidationRule', { what: t('password') })
    if (!value) error = error = "Password is required";
    if (passValue && value !== passValue) error = "Password does not match";

    this.setState((prevState) => ({ error: { ...prevState.error, confirmPassword: error } }));
    return error;
  };

  validateAll = () => {
    this.validateConfirmationKey();
    this.validatePassword();
    this.validateConfirmPassword();

    return this.validateConfirmationKey() || this.validatePassword() || this.validateConfirmPassword();
  };

  submit = () => {
    let self = this;
    let PasswordRecoveryVo = {
      confirmationKey: this.state.confirmationKey.trim(),
      password: this.state.password.trim(),
    };

    if (this.validateAll()) {
    } else {
      utility.loadingModalLoader(this, "Please wait. Resetting the password...");
      this.setState({ isSubmitting: true });
      ajax.OrganizationMaster.updatePassword(PasswordRecoveryVo).end((err, response) => {
        this.setState({ isSubmitting: false });
        utility.unLoadingModalLoader(this);
        let errJson = utility.handleErrors(err);
        this.setState({ confirmationKey: "", password: "", confirmPassword: "" });
        if (!err && response) {
          if (response.body) {
            // self.props.history.push(`/`);
            this.resetForm();
            utility.loadDisplayMessage(this, "success", "Password has been reset successfully.", [
              "Please login using your username and new password.",
              <Button onClick={() => this.handleLoginBtnClick()} className="customBtn1Primary mt-1">
                Login
              </Button>,
            ]);
            // utility.loadDisplayMessage(this, "success", "Password has been reset successfully.", ['Please login using your username and new password.', <Button onClick={() => this.props.history.push(`/`)} className='customBtn1Primary mt-1'>Go to home</Button>]);
          } else utility.loadDisplayMessage(this, "warning", "Re-generate the confirmation key.", ["If the problem still persists. Please contact your system administrator."]);
        } else {
          utility.loadDisplayMessage(this, "warning", "Re-generate the confirmation key.", ["If the problem still persists. Please contact your system administrator"]);
        }
      });
    }
  };

  resetForm = () => {
    this.setState({
      confirmationKey: "",
      password: "",
      confirmPassword: "",
      showPass: false,
    });
  };

  handleLoginBtnClick = () => {
    sendAuthorizationRequest();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <MenuHeader />
        <Segment basic className="pt-0 px-2 my-0" style={{ minHeight: "calc(100vh - 174px)" }}>
          <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

          <Grid columns={1} centered>
            <Grid.Column computer={8} tablet={10} mobile={16}>
              <Segment>
                <div className="textBig colPrimary textBold">{t("resetPassword")}</div>

                <div>
                  <p className="textSmall textBold mb-0">Guidance Notes</p>
                  <p className="textSmaller colGrey40 mb-0">Password should have minimum 12 characters </p>
                  <p className="textSmaller colGrey40 mb-0">Password should be less than 30 characters</p>
                  <p className="textSmaller colGrey40 mb-0">Password should have at least one lower case (a-z)</p>
                  <p className="textSmaller colGrey40 mb-0">Password should have at least one number (0-9)</p>
                  <p className="textSmaller colGrey40 mb-0">Password should have at least one special character out of these acceptable characters ($ & ( ) * , @ [ ] ^ _ {} ~)</p>
                  <p className="textSmaller colGrey40 mb-0">Password should not include (! " % or +)</p>
                  <p className="textSmaller colGrey40 mb-0">Password should not contain spaces or new line characters</p>
                </div>
                <Form>
                  <Form.Field>
                    <Form.Input
                      className="mb-0"
                      name="confirmationKey"
                      fluid
                      value={this.state.confirmationKey}
                      onChange={(e) => this.handleTextChange(e)}
                      label={t("confirmationKey")}
                      placeholder={t("enterConfirmationKey")}
                      onBlur={() => this.validateConfirmationKey()}
                      required
                    />
                    {this.state.error.confirmationKey && <div className="errorText">{this.state.error.confirmationKey}</div>}
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>{t("password")}</label>
                      <Input
                        type={`${this.state.showPass ? "text" : "password"}`}
                        className="mb-0"
                        name="password"
                        fluid
                        value={this.state.password}
                        onChange={(e) => this.handleTextChange(e)}
                        placeholder={t("enterNewPassword")}
                        onBlur={() => this.validatePassword()}
                        required
                      />
                      {this.state.error.password && <div className="errorText">{this.state.error.password}</div>}
                      <Checkbox label="Show Password" onChange={() => this.setState((prevState) => ({ showPass: !prevState.showPass }))} checked={this.state.showPass} />
                    </Form.Field>
                    <Form.Field>
                      <label>{t("confirmPassword")}</label>
                      <Input
                        type="password"
                        className="mb-0"
                        name="confirmPassword"
                        fluid
                        value={this.state.confirmPassword}
                        onChange={(e) => this.handleTextChange(e)}
                        placeholder={t("confirmNewPassword")}
                        onBlur={() => this.validateConfirmPassword()}
                        required
                      />
                      {this.state.error.confirmPassword && <div className="errorText">{this.state.error.confirmPassword}</div>}
                    </Form.Field>
                  </Form.Group>
                  {/* <Form.Field >
                                        <div className='textSmall my-1'>
                                            <strong>Disclaimer : </strong> on successful reset of password, user will be auto redirected to the landing page and user can click Login button, when prompted the new credentials can be used.
                                        </div>
                                    </Form.Field> */}
                </Form>

                <Grid>
                  <Grid.Column computer={16} textAlign="right">
                    <Button fluid className="customBtn1Primary mr-0 mt-1" loading={this.state.isSubmitting} onClick={() => this.submit()}>
                      {t("submit")}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Segment>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(withRouter(ResetPassword));
